import React, { useEffect, useRef, useState } from "react";
import {
  RiCheckboxFill,
  RiEyeCloseFill,
  RiListCheck,
  RiUploadCloud2Line,
} from "react-icons/ri";
import { ImEye } from "react-icons/im";
import CryptoJS from "crypto-js";

import {
  useGetApplicationQuery,
  useGetCertificateFeeQuery,
  useUpdateApplicationMutation,
} from "../../../store/slices/apis/applicationApi";
import { IMAGE_BASE_URL } from "../../../Utils/Common";
import {
  useGetCollegesQuery,
  useGetCoursesQuery,
  useGetDegreeModesQuery,
  useGetDegreeTypesQuery,
  useGetDocumentsQuery,
  useGetGradesQuery,
  useGetSubjectsQuery,
  useLazyGetDegreeModesByCourseQuery,
  useLazyGetDegreeTypesByCourseQuery,
  useLazyGetSubjectsByCourseQuery,
} from "../../../store/slices/apis/masterApi";
import ImageModel from "../../../Components/Common/ImageModel";
import { toast } from "react-toastify";
import Loader from "../../../Components/Utils/Loader";
import { PaymentStatus } from "../../../Utils/Status";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

export const PreDegreeCertificateReviewPayment = ({
  data: application,
  certificateId,
  setTab,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const {
    data: documents = [],
    isLoading: isGetDocumentsLoading,
    isFetching: isGetDocumentsFetching,
    isSuccess: isGetDocumentsSuccess,
  } = useGetDocumentsQuery(certificateId);
  const hardcodedDocument = {
    id: 17,
    name: "Internship Completion certificate from Assam Medical College",
    crop: 0,
  };
  const [updatedDocuments, setUpdatedDocuments] = useState(documents);

  useEffect(() => {
    let newDocuments = [...documents];

    if ([45, 46, 47, 57].includes(application.course_id)) {
      newDocuments = [...newDocuments, hardcodedDocument];
    }

    setUpdatedDocuments(newDocuments);
  }, [documents, application.course_id]);
  const {
    data: fee = [],
    isLoading: isGetFeeLoading,
    isFetching: isGetFeeFetching,
    isSuccess: isGetFeeSuccess,
  } = useGetCertificateFeeQuery(application?.id);

  const handleCheckBoxChange = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    const allChecked = Array.from(checkboxes).every(
      (checkbox) => checkbox.checked
    );
    setIsChecked(allChecked);
  };
  const [isUploaded, setIsUploaded] = useState(true); // Assuming it's initially true

  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [showImageModel, setShowImageModel] = useState(null);

  const printRef = useRef();

  const Print = useReactToPrint({
    content: () => printRef.current,
  });

  const [paymentString, setPaymentString] = useState("");
  const [updateApplication] = useUpdateApplicationMutation();
  const {
    data: colleges = [],
    isLoading: isLoadingGetColleges,
    isSuccess: isSuccessGetColleges,
    isError: isErrorGetColleges,
  } = useGetCollegesQuery();
  const {
    data: grades = [],
    isLoading: isLoadingGetGrades,
    isSuccess: isSuccessGetGrades,
    isError: isErrorGetGrades,
  } = useGetGradesQuery();
  const {
    data: courses = [],
    isLoading: isLoadingGetCourses,
    isSuccess: isSuccessGetCourses,
    isError: isErrorGetCourses,
  } = useGetCoursesQuery();

  const [
    getDegreeTypesByCourse,
    {
      data: degreeTypes = [],
      isFetching: isFetchingGetDegreeTypes,
      isLoading: isLoadingGetDegreeTypes,
      isSuccess: isSuccessGetDegreeTypes,
      isError: isErrorGetDegreeTypes,
    },
  ] = useLazyGetDegreeTypesByCourseQuery();

  const [
    getDegreeModesByCourse,
    {
      data: degreeModes = [],
      isFetching: isFetchingGetDegreeModes,
      isLoading: isLoadingGetDegreeModes,
      isSuccess: isSuccessGetDegreeModes,
      isError: isErrorGetDegreeModes,
    },
  ] = useLazyGetDegreeModesByCourseQuery();

  const [
    getSubjectsByCourse,
    {
      data: subjects = [],
      isFetching: isFetchingGetSubjects,
      isLoading: isLoadingGetSubjects,
      isSuccess: isSuccessGetSubjects,
      isError: isErrorGetSubjects,
    },
  ] = useLazyGetSubjectsByCourseQuery();

  useEffect(() => {
    // Check if "Not Uploaded" exists in the page's text content
    if (document.body.innerText.includes("Not Uploaded")) {
      setIsUploaded(false);
    }

    getDegreeTypesByCourse(application?.course_id);
    getDegreeModesByCourse(application?.course_id);
    getSubjectsByCourse(application?.course_id);
  }, []);

  const isInvalid =
    application?.course_id !== 40 &&
    (!application?.grade_id || !application?.exam_roll_no);

  // const handleSubmit = async () => {
  //   try {
  //     setLoading(true);
  //     const result = await updateApplication({
  //       id: application.id,
  //       application_status: 1,
  //     }).unwrap();
  //     if (result.status) {
  //       toast("Saving Successful");
  //       navigate("/applied-certificates");
  //     } else {
  //       toast(result.message || "");
  //     }
  //   } catch (err) {
  //     toast(err?.data?.message || "");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the form data is valid before proceeding
    if (isInvalid) {
      alert(
        "Error: Please ensure that both Grade and Roll No fields are filled out."
      ); // Display an alert
      return;
    }

    console.log(subjects);

    if (degreeTypes.length > 0 && !application.degree_type_id) {
      alert("Error: Please select a Major/General field.");
      return;
    }

    // Perform the form submission here if everything is valid
    // ...

    const form = document.getElementById("payment");
    form.submit();
  };

  const [selectedFee, setSelectedFee] = useState(650); // default fee
  const [postalFee, setPostalFee] = useState(0);
  useEffect(() => {
    if (application?.certificate_delivery_type == 2) {
      setPostalFee(250);
    } else if (application?.certificate_delivery_type == 3) {
      if (application?.postal_country == "India") {
        setPostalFee(250);
      } else {
        setPostalFee(2500);
      }
    } else {
      setPostalFee(0);
    }
  }, [application]);

  useEffect(() => {
    const bifergator = "GENERAL";
    const payment_type = application?.certificate?.name;

    const uniqueTimeStampInSeconds = moment().unix();
    const gatewayUniqueId = `${application?.id}_${uniqueTimeStampInSeconds}`;
    
    const str = `DIBRUGARHU|${gatewayUniqueId}|NA|${
      selectedFee + postalFee
    }|NA|NA|NA|INR|NA|R|dibrugarhu|NA|NA|F|${application.name}|${
      application.phone_number
    }|${bifergator}|${
      application.course_id
    }|NA|${payment_type}|NA|https://certificateapi.dibru.work/gateway/response`;

    // $checksum = hash_hmac('sha256',$str,'EVPxLxSVgNE7', false);
    // $checksum = strtoupper($checksum);
    // $message=$str.'|'.$checksum;

    const secretKey = "EVPxLxSVgNE7";

    // Calculate the HMAC SHA-256 checksum
    const hmac = CryptoJS.HmacSHA256(str, secretKey);
    const checksum = hmac.toString(CryptoJS.enc.Hex).toUpperCase();
    // const checksum = hmac.toUpperCase();

    // Combine the original string and checksum
    const message = str + "|" + checksum;
    setPaymentString(message);
  }, [selectedFee, application, postalFee]);

  // Now 'message' contains the same result as in the PHP code

  return (
    <div ref={printRef}>
      {isLoading && <Loader />}
      {application?.application_status == 1 && (
        <div className='text-center'>
          {/* {application?.payment_status === PaymentStatus.PAID && ( )} */}

          <img
            src='../../assets/DU.png'
            alt='university_logo'
            className='w-3/6 m-auto'
          />
          <h2 className='text-2xl font-bold'>
            Application form for {application?.certificate?.name}
          </h2>
        </div>
      )}
      {showImageModel && (
        <ImageModel
          url={showImageModel}
          onClose={() => setShowImageModel(null)}
        />
      )}
      <div className='w-full p-10 space-y-8 bg-white shadow-2xl'>
        <div className=''>
          {application?.application_status == 1 && (
            <>
              <div className='text-lg font-bold'>
                Application No.: {application?.id}
              </div>
              <div className='text-lg font-bold'>
                Applied Date:{" "}
                {moment(application?.transaction_date).format("DD/MM/YYYY")}
              </div>
              <div className='text-lg font-bold'>
                Status:{" "}
                <span className='text-green-600'>Successfully Submitted</span>
              </div>
            </>
          )}
          {application?.application_status == 0 && (
            <div className='mb-2 text-lg font-bold'>
              Status: <span className='text-red-600'>Payment Pending </span>
            </div>
          )}

          <div className='flex flex-wrap'>
            <div className='w-2/6 space-y-2 font-bold'>
              <div>Name of the Candidate (English):</div>
            </div>
            <div className='w-4/6'>
              <div className='relative flex'>
                <div className='w-2/3 space-y-2'>
                  <div>{application?.name}</div>
                </div>
                <div className='absolute right-0 w-1/3 space-y-2'>
                  {application?.applicant_photo && (
                    <img
                      className='h-32 mt-auto ml-auto'
                      src={`${IMAGE_BASE_URL}${application?.applicant_photo}`}
                      alt='ProfilePhoto'
                    />
                  )}
                  {!application?.applicant_photo && (
                    <span className='float-right w-64 px-4 py-2 text-gray-700 bg-gray-100 rounded'>
                      Photo Not Uploaded
                    </span>
                  )}
                  {application?.signature && (
                    <img
                      className='w-32 h-10 mt-auto ml-auto'
                      src={`${IMAGE_BASE_URL}${application?.signature}`}
                      alt='Signature'
                    />
                  )}
                  {!application?.signature && (
                    <span className='float-right w-64 px-4 py-2 mt-auto ml-auto text-gray-700 bg-gray-100 rounded'>
                      Signature Not Uploaded
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>Father's Name:</div>
            <div className='w-4/6'>{application?.father_name}</div>
          </div>

          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>
              Registration No of Dibrugarh University:
            </div>
            <div className='w-4/6'>{application?.registration_no}</div>
          </div>

          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>Degree applied for :</div>
            {isSuccessGetCourses && (
              <div className='w-4/6'>
                {courses?.find((course) => course.id === application?.course_id)
                  ?.name || ""}
              </div>
            )}
          </div>

          {application?.degree_mode_id > 0 && (
            <div className='flex flex-wrap space-y-2'>
              <div className='w-2/6 font-bold'> Mode :</div>
              {isSuccessGetDegreeModes && (
                <div className='w-4/6'>
                  {degreeModes?.find(
                    (mode) => mode.id === application?.degree_mode_id
                  )?.name || ""}
                </div>
              )}
            </div>
          )}

          {application?.course_id != 40 && (
            <>
              <div className='flex flex-wrap space-y-2'>
                <div className='w-2/6 font-bold'>Roll & No :</div>
                <div className='w-4/6'>
                  {application?.exam_roll_no ? (
                    application?.exam_roll_no
                  ) : (
                    <>
                      {application?.exam_roll} - {application?.exam_no}
                    </>
                  )}
                </div>
              </div>

              <div className='flex flex-wrap space-y-2'>
                <div className='w-2/6 font-bold'>Class/Division/Grade :</div>
                {isSuccessGetGrades && (
                  <div className='w-4/6'>
                    {grades?.find((grade) => grade.id === application?.grade_id)
                      ?.name || ""}
                  </div>
                )}
              </div>
            </>
          )}
          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>
              Final Semester/Year examination held in (Month/Year) :
            </div>
            <div className='w-4/6'>
              {moment(application?.final_sem).format("MM/YYYY")}
            </div>
          </div>

          {/* <div className="flex flex-wrap space-y-2">
            <div className="w-2/6 font-bold">Year of Qualifying :</div>
            <div className="w-4/6">{application?.year_of_qualify}</div>
          </div> */}

          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>Date of passing :</div>
            <div className='w-4/6'>
              {moment(application?.date_of_passing).format("DD/MM/YYYY")}
            </div>
          </div>

          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>
              College/Department/Institute from where qualified :
            </div>
            {isSuccessGetColleges && (
              <div className='w-4/6'>
                {colleges?.find(
                  (college) => college.id === application?.college_id
                )?.name || ""}
              </div>
            )}
          </div>

          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>Mobile No :</div>
            <div className='w-4/6'>{application?.phone_number}</div>
          </div>

          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>Email :</div>
            <div className='w-4/6'>{application?.email}</div>
          </div>

          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>Address for Communication:</div>
            <div className='w-4/6'>
              <p>
                Village/Town: {application?.village}, House No./Street No.:
                {application?.house_no}, P.O: {application?.postoffice},
                District:
                {application?.district}, State: {application?.state?.name}, Pin:
                {application?.pincode}
              </p>
            </div>
          </div>
          {application?.certificate_delivery_type == 3 && (
            <div className='flex flex-wrap space-y-2'>
              <div className='w-2/6 font-bold'>Postal Address:</div>
              <div className='w-4/6'>
                <p>
                  Village/Town: {application?.postal_village}, House No./Street
                  No.:
                  {application?.postal_house_no}, P.O: {application?.postal_po},
                  District:
                  {application?.postal_district}, State:{" "}
                  {application?.postal_state_name}, Country:{" "}
                  {application?.postal_country}, Pin:
                  {application?.postal_pincode}
                </p>
              </div>
            </div>
          )}

          {updatedDocuments &&
            updatedDocuments
              ?.filter((d) => {
                if (application.course_id == 40) {
                  return d.id == 1;
                } else {
                  return true;
                }
              })
              ?.map((document) => {
                const exists = application?.documents?.find(
                  (doc) => doc.document_id === document.id
                );
                return (
                  <div key={document.id} className='flex flex-wrap space-y-2'>
                    <div className='w-2/6 font-bold'>
                      {document.name || ""} :
                    </div>
                    {exists?.file ? (
                      <span className='px-4 py-2 text-green-700 bg-green-100 rounded'>
                        Uploaded
                      </span>
                    ) : (
                      <span className='px-4 py-2 text-gray-700 bg-gray-100 rounded'>
                        Not Uploaded
                      </span>
                    )}
                    {application.application_status == 0 && (
                      <button
                        className='px-3 py-2 text-sm font-bold text-blue-600 uppercase rounded hover:bg-blue-600 hover:text-white'
                        type='button'
                        onClick={() => {
                          setShowImageModel(exists?.file || null);
                        }}
                      >
                        {exists?.file ? "View" : ""}
                        <ImEye className='inline-block ml-1' />
                      </button>
                    )}
                  </div>
                );
              })}
        </div>

        <hr className='my-6 border border-gray-400 border-opacity-50' />

        <div class='container mx-auto'>
          <div class='p-2'>
            <h2 class='font-bold text-xl'>UNDERTAKING</h2>
            <p class='text-lg'>I hereby declare the following,</p>

            <div class='text-lg space-y-1'>
              <div class='space-x-2'>
                <input
                  defaultChecked={application.application_status == 1}
                  id='checkbox1'
                  type='checkbox'
                  onChange={handleCheckBoxChange}
                />
                <label class='font-semibold' for='checkbox1'>
                  I have uploaded the correct documents during the online
                  application and ensured that the scanned images of such
                  documents are distinctly visible.
                </label>
              </div>

              <div class='space-x-2'>
                <input
                  defaultChecked={application.application_status == 1}
                  id='checkbox2'
                  type='checkbox'
                  onChange={handleCheckBoxChange}
                />
                <label class='font-semibold' for='checkbox2'>
                  I have provided my own mobile No. and email id at the time of
                  online application.
                </label>
              </div>

              <div class='space-x-2'>
                <input
                  defaultChecked={application.application_status == 1}
                  id='checkbox3'
                  type='checkbox'
                  onChange={handleCheckBoxChange}
                />
                <label class='font-semibold' for='checkbox3'>
                  If any of the information provided by me is found to be
                  incorrect/illegible, my application shall be liable to be
                  cancelled.
                </label>
              </div>
            </div>

            <hr class='border border-gray-400 border-opacity-50 my-6' />

            {application?.application_status == 0 && (
              <div>
                <div>
                  <h2 class='font-bold text-xl'>PAYMENT INFO</h2>
                  <p class='text-lg'>
                    [You can select any one of the payment options available i.e
                    Netbanking, Debit card (Visa, Master, Maestro, RuPay),
                    credit card etc. The ATM-cum debit card option is for older
                    versions of ATM cards which is now supported only by limited
                    no. of banks.]
                  </p>

                  <div className='my-5 text-lg row'>
                    <div className='font-semibold col'>
                      Application form for: {application?.certificate?.name}
                    </div>
                    <div class='col'>
                      <span className='ml-2'>
                        Amount: (Rs. {(selectedFee + postalFee).toFixed(2)})
                      </span>
                    </div>
                  </div>
                </div>

                <hr />

                <div class='mt-3 flex justify-between'>
                  <button
                    type='button'
                    class='bg-green-500 text-white py-2 px-4 rounded'
                    onClick={() => {
                      setTab(3);
                    }}
                  >
                    Previous
                  </button>

                  {/* <button
                class='bg-blue-500 text-white py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed'
                type='submit'
                onClick={handleSubmit}
                disabled={!isChecked || !selectedFee}
              >
                Confirm and Proceed to Pay Rs. {selectedFee}
              </button> */}
                  {courses?.find(
                    (course) => course.id === application?.course_id
                  )?.active == 1 && (
                    <form
                      name='payment'
                      id='payment'
                      method='POST'
                      action='https://pgi.billdesk.com/pgidsk/PGIMerchantPayment'
                      onSubmit={handleSubmit}
                    >
                      <input
                        type='hidden'
                        name='msg'
                        id='msg'
                        value={paymentString}
                      />

                      <input
                        type='submit'
                        disabled={!isChecked || !selectedFee || !isUploaded}
                        class='bg-blue-500 text-white py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer'
                        value={` Confirm and Proceed to Pay Rs. ${
                          selectedFee + postalFee ?? ""
                        }`}
                      />
                    </form>
                  )}
                </div>
              </div>
            )}

            {application?.application_status == 1 && (
              <div>
                <div>
                  <h2 class='font-bold text-xl'>PAYMENT INFO</h2>
                  <p class='text-lg font-semibold'>
                    Transaction Date: {application?.transaction_date} <br />
                    Applied For: {application?.payment_type} <br />
                    Transaction No: {application?.transaction_no} <br />
                    Bank Reference No: {application?.bank_ref_no} <br />
                    Amount: {application?.amount_paid} <br />
                  </p>
                </div>

                <hr />
                <div className='flex flex-col items-center justify-center'>
                  <button
                    className='flex items-center px-6 py-2 mt-1 text-lg font-normal leading-normal text-white no-underline whitespace-no-wrap bg-teal-500 border rounded select-none noPrint hover:bg-teal-600 screen-only'
                    onClick={Print}
                  >
                    Print
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreDegreeCertificateReviewPayment;
